import * as React from "react";
import { graphql, HeadFC, Link, PageProps } from "gatsby";
import { Seo } from "components/Seo";
import { Layout } from "components/Layout";

const CategoryListPage: React.FC<PageProps<Queries.CategoryListQuery>> = ({
  data,
}) => {
  return (
    <Layout>
      <h1 className="text-3xl font-bold underline">カテゴリ一覧</h1>
      <ul>
        {data.allMicrocmsPosts.edges.map(({ node }) => {
          return (
            <li key={node.category?.name}>
              <Link to={`/category/${node.category?.slug}`}>
                <span>{node.category?.name}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query CategoryList {
    allMicrocmsPosts {
      totalCount
      edges {
        node {
          category {
            name
            slug
          }
        }
      }
    }
  }
`;

export default CategoryListPage;

export const Head: HeadFC = () => <Seo title="投稿一覧" />;
